.home_main{
    color: white;
    font-family: 'Space Grotesk', sans-serif;
    height: 100vh;
    width: 100%;
    position: absolute;
    transition: all 0.75s ease-in-out;
    z-index: 50;
}

.vimeo-wrapper {
    background-color: black;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    pointer-events: none;
    overflow: hidden;
 }
 .vimeo-wrapper iframe {
    width: 100vw;
    height: 56.25vw;
    min-height: 100vh;
    min-width: 177.77vh;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
 }

#home_video{
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -5;
}

.home_tape{
    bottom: 50px;
    left: 54px;
    position: absolute;
    width: 120px;
}

.home_head{
    font-size: 150px;
    line-height: 120px;
    position: absolute;
}

.home_head1{
    top: 50px;
    left: 54px;
}

.home_head2{
    bottom: 50px;
    right: 54px;
}

.home_smile{
    font-size: 50px;
    position: absolute;
    right: 54px;
    top: 60px;
}

.home_main ul{
    display: flex;
    font-size: 24px;
    font-weight: 700;
    flex-direction: column;
    justify-content: center;
    list-style: none;
    line-height: 50px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 15%;
}

.home_main ul a{
    color: white;
    text-decoration: none;
}

.home_main ul a:hover{
    text-decoration: underline;
}

.home_main ul img{
    display: none;
    width: 60px;
    margin-top: 18px;
    margin-left: -8px;
}

#enter_button{
    align-items: center;
    background-color: transparent;
    border: none;
    color: white;
    cursor: pointer;
    display: flex;
    font-family: 'Space Grotesk', sans-serif;
    font-weight: 700;
    font-size: 24px;
    position: absolute;
    top: 48%;
    right: 20%;
}


/* Responsiveness */

@media only screen and (max-width: 1280px) {
    .home_head{
        font-size: 120px;
        line-height: 90px;
    }

    .home_head1{
        left: 48px;
    }

    .home_head2{
        right: 48px;
    }

    .home_smile{
        font-size: 40px;
        right: 48px;
    }

    .home_tape{
        width: 100px;
        left: 48px;
    }
    
    .home_main ul{
        font-size: 20px;
        line-height: 42px;
    }

    #enter_button{
        font-size: 20px;
    }
}


@media only screen and (max-width: 1080px) {
    .home_head{
        font-size: 100px;
        line-height: 70px;
    }
    
    .home_head1{
        top: 44px;
        left: 44px;
    }

    .home_head2{
        right: 44px;
        bottom: 44px;
    }

    .home_smile{
        top: 52px;
        font-size: 34px;
        right: 44px;
    }

    .home_tape{
        width: 90px;
        left: 44px;
        bottom: 44px;
    }
    
    .home_main ul{
        font-size: 18px;
        line-height: 38px;
    }

    #enter_button{
        font-size: 18px;
    }
}


@media only screen and (max-width: 840px) {
    .home_main{
        transition: all 0.4s ease-in-out;
    }

    .home_head{
        font-size: 80px;
        line-height: 60px;
    }
    
    .home_head1{
        top: 32px;
        left: 28px;
    }

    .home_head2{
        right: 28px;
        bottom: 32px;
    }

    .home_smile{
        top: 42px;
        font-size: 28px;
        right: 28px;
    }

    .home_tape{
        width: 80px;
        left: 28px;
        bottom: 32px;
    }
    
    .home_main ul{
        font-size: 16px;
    }

    #enter_button{
        font-size: 16px;
    }
}


@media only screen and (max-width: 640px) {
    .home_head{
        font-size: 60px;
        line-height: 44px;
    }
    
    .home_head1{
        top: 24px;
        left: 20px;
    }

    .home_head2{
        right: 20px;
        bottom: 24px;
    }

    .home_smile{
        top: 27px;
        right: 20px;
    }

    .home_tape{
        display: none;
    }
    
    .home_main ul{
        left: 10%;
    }

    .home_main ul img{
        display: inline-block;
    }

    #enter_button{
        top: 58%;
        right: 12%;
    }
}