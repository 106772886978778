.socials{
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100vh;
    position: fixed;
    row-gap: 28px;
    right: 23px;
    top: 0;
    z-index: 8;
}

.socials svg{
    cursor: pointer;
}

.socials svg:hover{
    opacity: 0.6;
}

.socials svg:active{
    opacity: 1;
}

@media only screen and (max-width: 740px) {
    .socials{
        display: none;
    }
  }