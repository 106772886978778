.about{
    font-family: 'Space Grotesk', sans-serif;
    height: 100vh;
    padding-bottom: 54px;
    position: relative;
}

.about_bg{
    align-items: center;
    background-color: #CCE6F4;
    display: flex;
    flex-direction: column;
    justify-content: center;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    overflow: hidden;
    z-index: -10;
}

.about_bg p{
    color: #4B4B4B;
    font-weight: bold;
    font-size: 28vw;
    user-select: none;
}

.about_details{
    align-items: center;
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 28px;
    column-gap: 48px;
    display: flex;
    height: 100%;
    margin-right: 12px;
    padding: 28px 62px;
}

.about_details img{
    object-fit: contain;
    height: 80%;
    width: 288px;
}

.about_details div{
    height: 85%;
    overflow: auto;
}

.about_details h1{
    font-family: 'Space Grotesk', sans-serif;
    font-weight: bold;
    margin-bottom: 14px;
    font-size: 44px;
}

.about_details p{
    font-weight: 700;
    font-size: 20px;
    margin-left: 8px;
    margin-bottom: 28px;
}

.about_details div::-webkit-scrollbar {
    width: 0px;
}

/* Responsiveness */

@media only screen and (max-width: 780px) {
    .about_bg p{
        font-size: 200px;
        transform: rotate(-90deg);
        white-space: nowrap;
    }
    
    .about_details{
        flex-direction: column;
        row-gap: 16px;
        padding: 28px 14px;
    }

    .about_details div{
        padding: 0 14px;
    }
    .about_details img{
        height: auto;
        min-width: 150px;
        width: 30%;
    }

    .about_details h1{
        font-size: 34px;
    }

    .about_details p{
        font-size: 18px;
        margin-left: 0;
    }
}

@media only screen and (max-width: 740px) {
    .about{
        padding-left: 34px;
        padding-right: 34px;
    }

    .about_details{
        margin-right: 0;
    }
}

@media only screen and (max-height: 740px) and (max-width: 740px) {
    .about_bg p{
        font-size: 160px;
    }
}