@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@400;500;700&display=swap');

*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

section {
  padding: 113px 54px 0;
}

button:hover{
  opacity: 0.85;
}

button:active{
  opacity: 0.7;
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.45);
  border-radius: 4px;
}