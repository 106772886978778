.navbar{
    align-items: center;
    background-color: rgba(240, 240, 240, 0.9);
    border-radius: 28px;
    display: flex;
    font-family: 'Space Grotesk', sans-serif;
    justify-content: space-between;
    user-select: none;
    padding: 9px 45px;
    position: fixed;
    left: 13px;
    right: 13px;
    top: 18px;
    z-index: 10;
}

.navbar h1{
    color: black;
    cursor: pointer;
    font-size: 46px;
}

.navbar_menu{
    align-items: center;
    column-gap: 41px;
    display: flex;
    font-weight: 700;
    font-size: 18px;
}

.navbar_option{
    color: black;
    cursor: pointer;
    margin-bottom: -1px;
    text-decoration: none;
}

.navbar_menu > .active {
    text-decoration: underline;
}

.navbar_option:hover{
    text-decoration: underline;
}

.navbar_hamburger, .navbar_close{
    cursor: pointer;
    display: none;
    width: 30px;
}

/* Responsiveness */

@media only screen and (max-width: 1040px) {
    .navbar h1{
        font-size: 40px;
    }
    
    .navbar_menu{
        column-gap: 34px;
        font-size: 17px;
    }
}

@media only screen and (max-width: 920px) {
    .navbar h1{
        font-size: 38px;
    }
    
    .navbar_menu{
        background-color: rgba(240, 240, 240, 0.9);
        border-bottom-left-radius: 28px;
        border-bottom-right-radius: 28px;
        display: none;
        flex-direction: column;
        font-size: 18px;
        padding: 34px 0;
        row-gap: 32px;
        position: absolute;
        left: 0;
        right: 0;
        top: 67px;
        z-index: 21;
    }

    .navbar_hamburger{
        display: inline-block;
    }
}

@media only screen and (max-width: 640px) {
    .navbar{
        padding: 14px 25px;
    }

    .navbar h1{
        font-size: 26px;
    }

    .navbar_menu{
        top: 61px;
    }

    .navbar_hamburger, .navbar_close{
        width: 26px;
    }
}