.tunes{
    font-family: 'Space Grotesk', sans-serif;
    height: 100vh;
    position: relative;
    padding-top: 0;
}

.tunes_container{
    display: flex;
    justify-content: space-around;
    height: 100%;
    padding-top: 113px;
    padding-bottom: 80px;
    overflow: scroll;
}

.music_box{
    width: 300px;
}

.music_first{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 1120px;
}

.music_middle{
    align-items: flex-end;
    display: flex;
    height: 850px;
}

.music_last{
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 1280px;
}

.tunes_container::-webkit-scrollbar {
    width: 0px;
}


.marquee {
    align-items: center;
    background-color: #000000;
    color: #ffffff;
    display: flex;
    font-family: 'Space Grotesk', sans-serif;
    font-size: 30px;
    height: 60px;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    max-width: 100%;
    overflow-x: hidden;
}
  
.track {
    animation: marquee 15s linear infinite;
    position: absolute;
    white-space: nowrap;
    will-change: transform;
}

.track span{
    font-weight: bold;
}
  
@keyframes marquee {
    from { transform: translateX(0); }
    to { transform: translateX(-50%); }
}

.tunes_bg{
    align-items: center;
    background-color: #2E86AB;
    display: flex;
    flex-direction: column;
    justify-content: center;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    overflow: hidden;
    z-index: -10;
}

.tunes_bg p{
    color: white;
    font-weight: bold;
    font-size: 35vw;
    user-select: none;
}

.tunes_empty_box{
    display: none;
}


/* Responsiveness */


@media only screen and (max-width: 1180px) {
    .music_box{
        width: 270px;
    }
}


@media only screen and (max-width: 980px) {
    .music_box{
        width: 250px;
    }
}


@media only screen and (max-width: 880px) {
    .tunes_container{
        align-items: center;
        flex-direction: column;
        padding-top: 513px;
        row-gap: 50px;
    }

    .tunes_empty_box{
        display: block;
    }    
    
    .music_first{
        height: auto;
        row-gap: 50px;
        padding-top: 400px;
    }

    .music_last{
        height: auto;
        row-gap: 50px;
    }

    .music_box{
        width: 260px;
    }

    .marquee{
        font-size: 24px;
        height: 48px;
    }

    .tunes_bg p{
        font-size: 220px;
        transform: rotate(-90deg);
    }
}


@media only screen and (max-width: 640px) {
    .marquee{
        font-size: 20px;
    }
}


@media only screen and (max-height: 1280px) and (max-width: 880px){
    .tunes_empty_box{
        padding: 60px 0;
    }
}


@media only screen and (max-height: 1120px) and (max-width: 880px){
    .tunes_empty_box{
        padding: 110px 0;
    }
}


@media only screen and (max-height: 1000px) and (max-width: 880px){
    .tunes_empty_box{
        padding: 178px 0;
    }
}


@media only screen and (max-height: 900px) and (max-width: 880px){
    .tunes_empty_box{
        padding: 220px 0;
    }
}


@media only screen and (max-height: 800px) and (max-width: 880px){
    .tunes_empty_box{
        padding: 280px 0;
    }
}


@media only screen and (max-height: 700px) and (max-width: 880px){
    .tunes_empty_box{
        padding: 328px 0;
    }
}


@media only screen and (max-height: 600px) and (max-width: 880px){
    .tunes_empty_box{
        padding: 360px 0;
    }
}


@media only screen and (max-height: 540px) and (max-width: 880px){
    .tunes_empty_box{
        padding: 420px 0;
    }
}


@media only screen and (max-height: 740px) and (max-width: 880px) {
    .tunes_bg p{
        font-size: 160px;
    }
}

