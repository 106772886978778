.contact{
    font-family: 'Space Grotesk', sans-serif;
    height: 100vh;
    position: relative;
}

.contact_bg{
    align-items: flex-start;
    background-color: #000000;
    display: flex;
    flex-direction: column;
    justify-content: center;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    overflow: hidden;
    z-index: -10;
}

.contact_bg p{
    color: rgba(161, 161, 161, 0.8);
    font-weight: bold;
    font-size: 262px;
    user-select: none;
    padding-top: 100px;
    margin-left: 80px;
}


.contact_container{
    align-items: center;
    display: flex;
    height: calc(100vh - 120px);
    justify-content: flex-end;
}

.contact_box{
    align-items: center;
    background-color: rgba(217, 217, 217, 0.8);
    border-radius: 28px;
    display: flex;
    flex-direction: column;
    font-weight: bold;
    justify-content: center;
    height: 500px;
    width: 650px;
    padding: 54px;
    row-gap: 12px;
    margin-right: 40px;
    z-index: 1;
}

.contact_box p{
    font-size: 50px;
    text-align: left;
    margin-bottom: 32px;
}

.contact_input{
    border: 2px solid #000000;
    border-radius: 12px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.2);
    font-family: 'Space Grotesk', sans-serif;
    font-size: 15px;
    font-weight: bold;
    text-align: center;
    outline: none;
    padding: 8px 20px;
    width: 48%;
}

.contact_input::placeholder {
    color: #000000;
}

.contact_box button{
    background-color: #000000;
    border: 2px solid #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.2);
    border-radius: 12px;
    color: #ffffff;
    cursor: pointer;
    font-family: 'Space Grotesk', sans-serif;
    font-weight: bold;
    font-size: 15px;
    padding: 8px 28px;
    margin-top: 6px;
}


@media only screen and (max-width: 1180px) {
    .contact_bg p{
        font-size: 242px;
    }
    
    .contact_box{
        font-size: 44px;
        height: 460px;
        width: 580px;
    }
}

@media only screen and (max-width: 1080px) {
    .contact_bg p{
        font-size: 222px;
    }
    
    .contact_box{
        height: 444px;
        width: 520px;
    }

    .contact_box p{
        font-size: 40px;
    }

    .contact_input{
        width: 56%;
    }
}


@media only screen and (max-width: 780px) {
    .contact_bg{
        align-items: center;
        justify-content: flex-end;
        padding-bottom: 40px;
    }

    .contact_bg p{
        font-size: 200px;
        padding: 0;
        margin: 0;
        transform: rotate(-90deg);
    }

    .contact_container{
        justify-content: center;
    }

    .contact_box{
        height: 80%;
        margin-right: 0;
    }

    .contact_input{
        width: 58%;
    }
}


@media only screen and (max-width: 700px) {
    .contact_bg p{
        font-size: 180px;
    }

    .contact_box p{
        font-size: 35px;
    }

    .contact_input{
        width: 70%;
    }
}


@media only screen and (max-width: 520px) {
    .contact_bg p{
        font-size: 140px;
    }

    .contact_box p{
        font-size: 35px;
    }

    .contact_input{
        width: 95%;
    }
}


@media only screen and (max-height: 680px) and (max-width: 520px) {
    .contact_box{
        height: 95%;
        margin-top: -20px;
    }
}


@media only screen and (max-height: 680px) and (min-width: 780px) {
    .contact_bg p{
        font-size: 202px;
    }
    
    .contact_box p{
        font-size: 32px;
        margin-bottom: 16px;
    }

    .contact_box{
        row-gap: 8px;
        height: 85%;
        width: 520px;
    }
}
