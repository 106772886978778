.music{
    width: 100%;
    display: flex;
    flex-direction: column;
}

.music_image{
    width: 100%;
}

.music_details{
    align-items: center;
    padding: 24px 20px;
    display: flex;
    justify-content: space-between;
}

.music_details h1{
    color: white;
    font-family: 'Space Grotesk', sans-serif;
    font-size: 20px;
}

.music_link{
    display: flex;
    column-gap: 20px;
}

.music_link img{
    cursor: pointer;
    height: 22px;
    margin-right: 4px;
}

.music_link img:hover{
    opacity: 0.8;
}


/* Responsiveness */


@media only screen and (max-width: 1180px) {
    .music_link img{
        column-gap: 18px;
    }

    .music_details h1{
        width: 120px;
    }    
}

@media only screen and (max-width: 980px) {
    .music_link img{
        margin-right: 0;
    }
}
