.popup {
  align-items: center;
  background-color: rgba(248, 203, 56, 0.89);
  border-radius: 32px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
  column-gap: 12px;
  display: flex;
  justify-content: center;
  position: absolute;
  top: 122px;
  right: 0px;
  opacity: 0;
  transition: all 0.6s ease-in-out;
  padding: 9px 22px 8px 24px;
  z-index: 10;
}

.popup p {
  color: white;
  font-size: 18px;
  font-family: "Space Grotesk", sans-serif;
  font-weight: bold;
  user-select: none;
}

.popup img {
  cursor: pointer;
  width: 18px;
}
