.things{
    font-family: 'Space Grotesk', sans-serif;
    height: 100vh;
    padding-bottom: 54px;
    position: relative;
}

.things_bg{
    align-items: center;
    background-color: #388659;
    display: flex;
    flex-direction: column;
    justify-content: center;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    overflow: hidden;
    z-index: -10;
}

.things_bg p{
    color: white;
    font-weight: bold;
    font-size: 35vw;
    user-select: none;
}

.things_box{
    background-color: rgba(255, 255, 255, 0.6);
    border-radius: 28px;
    display: flex;
    font-size: 20px;
    font-weight: 700;
    height: 100%;
    margin-right: 12px;
    padding: 0 58px 58px;
}

.things_folder, .things_gallery{
    align-items: center;
    color: black;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    text-decoration: none;
}

.things_tape{
    align-items: flex-end;
    display: flex;
    flex: 2;
    height: 100%;
}

.things_folder{
    flex: 2;
}

.things_gallery{
    flex: 4;
    padding-bottom: 106px;
    padding-right: 100px;
}

.things_tape img{
    width: 200px;
}

.things_folder img{
    width: 150px;
}

.things_gallery img{
    margin-bottom: 8px;
    width: 250px;
}


/* Responsiveness */

@media only screen and (max-width: 1080px) {
    .things_tape{
        flex: 1;
    }

    .things_folder{
        flex: 1;
    }

    .things_gallery{
        padding-right: 0;
    }
    
    .things_tape img{
        width: 180px;
    }

    .things_folder img{
        width: 114px;
    }

    .things_gallery img{
        width: 220px;
    }
}


@media only screen and (max-width: 880px) {
    .things_box{
        font-size: 18px;
    }

    .things_tape img{
        width: 150px;
    }
    
    .things_folder img{
        width: 80px;
    }

    .things_gallery img{
        width: 180px;
    }
}


@media only screen and (max-width: 740px) {
    .things{
        padding-left: 34px;
        padding-right: 34px;
    }

    .things_bg{
        padding-top: 60px;
    }

    .things_bg p{
        font-size: 220px;
        transform: rotate(-90deg);
    }

    .things_box{
        font-size: 16px;
        flex-direction: column;
        margin-right: 0;
        padding: 0 32px;
        position: relative;
    }

    .things_tape{
        bottom: 32px;
        height: auto;
        position: absolute;
    }

    .things_folder{
        flex: 2;
    }

    .things_gallery{
        flex: 3;
        justify-content: flex-start;
    }

    .things_tape img{
        width: 130px;
    }
    
    .things_folder img{
        width: 55px;
    }

    .things_gallery img{
        width: 150px;
    }
}


@media only screen and (max-height: 740px) and (max-width: 740px) {
    .things_bg p{
        font-size: 160px;
    }
}
