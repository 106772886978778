.tour {
    align-items: center;
    display: flex;
    font-family: 'Space Grotesk', sans-serif;
    justify-content: center;
    height: 100vh;
    padding-bottom: 54px;
    position: relative;
}


.tour_bg {
    align-items: center;
    background-color: #EEB902;
    display: flex;
    flex-direction: column;
    justify-content: center;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    overflow: hidden;
    z-index: -10;
}

.tour_bg p {
    color: white;
    font-weight: bold;
    font-size: 35vw;
    user-select: none;
}

.tour_box {
    align-items: center;
    background-color: rgba(255, 255, 255, 0.6);
    border-radius: 28px;
    display: flex;
    flex-direction: column;
    font-weight: 700;
    justify-content: space-around;
    height: 517px;
    width: 732px;
    padding: 58px
}

.tour_head {
    font-size: 64px;
    line-height: 90px;
    width: 100%;
}

.tour_head h1:first-child {
    transform: rotate(180deg);
    text-align: right;
    padding-right: 36px;
}

.tour_head h1:last-child {
    text-align: right;
    padding-right: 36px;
}

.tour_box p {
    font-size: 32px;
}



/* Responsiveness */

@media only screen and (max-width: 860px) {
    .tour_box {
        margin-right: 12px;
    }
}


@media only screen and (max-width: 740px) {
    .tour_box {
        margin-right: 0;
    }

    .tour_head {
        font-size: 54px;
        line-height: 78px;
    }

    .tour_head h1:first-child {
        padding-right: 24px;
    }

    .tour_head h1:last-child {
        padding-right: 24px;
    }
}


@media only screen and (max-width: 640px) {
    .tour {
        padding-left: 34px;
        padding-right: 34px;
    }

    .tour_bg {
        padding-top: 60px;
    }

    .tour_bg p {
        font-size: 220px;
        transform: rotate(-90deg);
    }
    

    .tour_head {
        font-size: 48px;
        line-height: 70px;
    }

    .tour_head h1:first-child {
        padding-right: 18px;
    }

    .tour_head h1:last-child {
        padding-right: 18px;
    }

    .tour_box p {
        font-size: 28px;
    }
}


@media only screen and (max-width: 540px) {
    .tour_box{
        padding: 32px;
    }

    .tour_head h1:first-child {
        padding-right: 0px;
    }

    .tour_head h1:last-child {
        padding-right: 0px;
    }

    .tour_box p {
        font-size: 28px;
    }
}


@media only screen and (max-width: 460px) {
    .tour_head {
        font-size: 38px;
        line-height: 60px;
    }

    .tour_box p {
        font-size: 24px;
    }

    .tour_head h1:first-child {
        padding-right: 0px;
    }

    .tour_head h1:last-child {
        padding-right: 0px;
    }

    .tour_box p {
        font-size: 28px;
    }
}


@media only screen and (max-width: 460px) {
    .tour_head {
        font-size: 38px;
        line-height: 60px;
    }

    .tour_box p {
        font-size: 24px;
    }
}


@media only screen and (max-height: 740px) {
    .tour_box {
        height: 457px;
    }
}


@media only screen and (max-height: 640px) {
    .tour_box {
        height: 400px;
        margin-top: 12px;
    }
}


@media only screen and (max-height: 740px) and (max-width: 640px) {
    .tour_bg p {
        font-size: 160px;
    }
}
