@import url('https://fonts.googleapis.com/css2?family=Lora:wght@700&display=swap');

.home{
    align-items: center;
    display: flex;
    height: 100vh;
    justify-content: space-evenly;
    position: relative;
}

.home_bg{
    filter: blur(14px) drop-shadow(-10px 10px 20px black);
    height: 100%;
    width: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -10;
}

.home_image{
    box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.25);
    width: 45%;
    max-width: 500px;
}

.home_details{
    color: #2D2726;
    font-size: 36px;
    font-family: 'Lora', serif;
    line-height: 94px;
    text-align: center;
}

.home_socials{
    align-items: center;
    column-gap: 32px;
    display: flex;
    justify-content: center;
}

.home_socials img{
    cursor: pointer;
    transform: rotate(90deg);
}

.home_socials img:hover{
    opacity: 0.8;
}


/* Responsiveness */

@media only screen and (max-width: 1280px) {
    .home_details{
        font-size: 30px;
        line-height: 88px;
        margin-right: 15px;
    }

    .home_socials {
        column-gap: 28px;
    }

    .home_socials img{
        height: 28px;
    }    
}


@media only screen and (max-width: 1080px) {
    .home_details{
        font-size: 24px;
        line-height: 78px;
    }

    .home_socials img{
        height: 24px;
    }    
}


@media only screen and (max-width: 920px) {
    .home{
        flex-direction: column;
        justify-content: center;
        row-gap: 20px;
    }
    
    .home_socials {
        column-gap: 24px;
    }

    .home_image{
        min-width: 340px;
        width: 55%;
        max-width: 580px;
    }
}


@media only screen and (max-width: 740px) {
    .home{
        padding-left: 28px;
        padding-right: 28px;
    }

    .home_details{
        font-size: 20px;
        line-height: 60px;
        margin-right: 0;
    }

    .home_details h1:last-child{
        font-size: 44px;
    }

    .home_socials img{
        margin: 18px 0;
    }    
}
